import React from "react"
import { Navbar } from "../components/navbar.component"
import Layout from "../components/layout.component"
import { NavbarBlog } from "../components/navbar.blog.component"
import { Thumbnail } from "../components/thumbnail.blog.component"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { graphql, Link } from "gatsby"
import { Slices } from "../components/slices.component"
import { linkFragment, linkResolver } from "../link-resolver"
import { Pagination } from "../components/common/pagination.component"
import SEO from "../components/common/seo.component"
import { Background } from "../components/common/background-image.component"
import { getLangPrefix } from "../utils/langs"

const BlogCategory = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const posts = data.prismic.allBlogPages.edges;
  const featured = posts.find(post => post.is_featured_article);
  const category = data.prismic.blogCategory
  const categories = data.prismic.allBlogCategorys.edges
  if (category) {
    return (
      <Layout>
        <SEO
          title={category.page_title || category.title}
          description={category.description || category.text}
          keywords={category.keywords}
          image={category.preview_image}
          lang={category._meta.lang}
        />
        <Navbar slides={[{}]} lang={category._meta.lang} />
        <section className="blog-section header header-light pb20" />
        <NavbarBlog lang={category._meta.lang} items={categories} />
        {featured ? (
          <Background image={featured.node.image} className="blog-banner">
            <div className="slider__wrap"/>
            <Link to={linkResolver(featured.node._meta)}>
              <div className="container">
                <div className="row">
                  <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                    <div className="blog-banner-header">
                      <small>Featured Article</small>
                      <br />
                      <h2>
                        <PlainStructuredText
                          structuredText={featured.node.title}
                        />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Background>
        ) : null}
        <section className="blog-section blog-section-pt-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                <h3>
                  <PlainStructuredText structuredText={category.title} />
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section className="blog-section blog-section-pt-5 pb100">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                <div className="thumbnail-wrapper">
                  {(posts &&
                    posts.map((item, index) => {
                      const post = item.node
                      return <Thumbnail post={post} key={`thumbnail${index}`} />
                    })) ||
                    null}
                </div>
              </div>
            </div>
          </div>
          {numPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              numPages={numPages}
              path={`${getLangPrefix(category._meta.lang)}/blog/${
                category._meta.uid
              }`}
            />
          ) : null}
        </section>
        <Slices body={category.body} large={false} />
      </Layout>
    )
  }
  return null
}

BlogCategory.fragments = [linkFragment]
export default BlogCategory

export const query = graphql`
  query blogCategoryQuery($lang: String!, $uid: String!, $id: String!) {
    prismic {
      blogCategory(lang: $lang, uid: $uid) {
        description
        keywords {
          keyword
        }
        text
        title
        page_title
        preview_image
        _meta {
          uid
          lang
          type
        }
        body {
          ... on PRISMIC_BlogCategoryBodyRich_text {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_BlogCategoryBodyForm {
            type
            label
            primary {
              bg_color
              bg_image
              form_script
              form_url
              text
            }
          }
          ... on PRISMIC_BlogCategoryBodyFeature {
            type
            label
            primary {
              bg_image
              bg_color
              text
            }
            fields {
              image
              link_style
              link_text
              text
              title
              link {
                ...link
              }
            }
          }
          ... on PRISMIC_BlogCategoryBodyBlock_with_text_and_image {
            type
            label
            primary {
              bg_color
              bg_image
              min_height
              image
              link_style
              link_text
              link {
                ...link
              }
              text
              title
            }
          }
        }
      }
      allBlogCategorys(lang: $lang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
              type
            }
          }
        }
      }
      allBlogPages(lang: $lang, where: {category: $id}, sortBy: meta_firstPublicationDate_DESC) {
            edges {
                node {
                    _meta {
                        uid
                        type
                        lang
                        firstPublicationDate
                    }
                    category {
                        ... on PRISMIC_BlogCategory {
                            title
                            _meta {
                                uid
                                type
                                lang
                            }
                        }
                    }
                    image
                    title
                    description
                    is_featured_article
                    page_title
                    preview_image
                }
            }
        }
    }
  }
`
